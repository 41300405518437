function addBackgroundGradient() {
    const partnerComponent = document.getElementsByClassName('partnerblock');

    if(partnerComponent) {
        for (const element of partnerComponent) {
            element.parentElement.parentElement.classList.add('partner--gradient');
        }
    }
}

document.addEventListener('DOMContentLoaded', () => {
    addBackgroundGradient();
});